<template>
	<nav id="navbar" class="nav-custom">
		<div class="section-title">
			<div class="title" v-if="title !=''">
				<h1 class="font-title-bold mb-0">
					{{ title }}
				</h1>
			</div>
<!-- 			<div class="subtitle" v-if="subtitle !=''">
				<h3>
					Subtitulo
				</h3>
			</div> -->
		</div>
		<div class="profile-nav">
			<div class="profile-nav-container">
				<div class="left">
					<div class="name">
						{{ user.primer_nombre + " " + user.apellido_paterno }}
					</div>
				</div>
				<div class="right me-3">
					<div class="img-container" @click="openList()">
						<img :src="handleUserImage()" alt="" />
					</div>
				</div>
			</div>
			<div class="profile-nav-list" v-if="open_list">
				<div class="border-arrow">
					<div class="arrow"></div>
				</div>
				<ul class="profile-nav__list">
					<li @click="$router.push({ name: 'Perfil' })">Mi Perfil</li>
					<li @click="logOut()">Cerrar Sesión</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>

export default {
	props: {
		title:    { require: true },
		subtitle: { require: true },
	},
	data() {
		return {
			user: this.$ls.get("user"),
			user_default_img: require("@/assets/img/user_default_img.svg"),
			open_list: false,
		};
	},
	methods: {
		handleUserImage() {
			if (
			this.user.imagen_perfil !== null &&
			this.user.imagen_perfil !== "" &&
			this.user.imagen_perfil !== undefined
			) {
			return this.user.imagen_perfil;
			} else {
			return this.user_default_img;
			}
		},
		openList() {
			if (this.open_list) {
			this.open_list = false;
			} else {
			this.open_list = true;
			}
		}
	}
};
</script>
